<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import GraspingRulesStores from './components/grasping_rules_stores.vue';

formCreate.component('graspingRulesStores', GraspingRulesStores);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'kms_grasping_rules_form',
      bsDirectId: '',
      bsInvoiceId: '',
      paramsRule: null,
      params: [],
      directSystemId: '',
      invoiceId: '',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'directSystemId') {
        item.restful = '/mdm/mdmDirectSystemController/directSystemSelect';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'directSystemName', value: 'directSystemId' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directSystemName',
        };
      }
      if (item.field === 'invoiceId') {
        item.restful = '/mdm/mdmGrabRuleController/orderTypeList';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'invoiceName', value: 'id' };
        item.restfulParamsGetValue = { directSystemId: 'directSystemId', bsDirectId: 'bsDirectId' };
        item.refresh = true;
      }
      if (item.field === 'loginAcc') {
        item.restful = '/mdm/mdmCustomerAccountController/page';
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'loginAcc', value: 'loginAcc' };
        item.restfulParams = { size: '-1' };
        item.restfulParamsGetValue = { directSystemId: 'directSystemId' };
        item.refresh = true;
      }
      if (item.field === 'graspingRulesStores') {
        item.props = {
          ...item.props,
          directSystemId: this.directSystemId,
          invoiceId: this.invoiceId,
        };
      }
      if (item.field === 'startDate') {
        item.props = {
          ...item.props,
          valueFormat: 'HH:mm',
          format: 'HH:mm',
        };
        item.on = {
          ...item.on,
          change: (e) => this.startDateChange(e),
        };
      }
      if (item.field === 'endDate') {
        item.props = {
          ...item.props,
          valueFormat: 'HH:mm',
          format: 'HH:mm',
        };
        item.on = {
          ...item.on,
          change: (e) => this.endDateChange(e),
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.basisRule = this.rule;
      const directSystemId = this.getRule('directSystemId');
      const graspingRulesStores = this.getRule('graspingRulesStores');
      directSystemId.on.change = (e) => {
        if (!e) {
          this.bsDirectId = '';
          this.setValue({
            bsDirectId: '',
            directSystemName: '',
          });
          this.directSystemId = '';
          graspingRulesStores.props.directSystemId = this.directSystemId;
          this.getParamsRule();
          return;
        }
        const selectData = directSystemId.options.find((item) => item.directSystemId === e);
        this.bsDirectId = selectData.bsDirectId;
        this.setValue({
          bsDirectId: selectData.bsDirectId,
          directSystemName: selectData.directSystemName,
        });
        this.directSystemId = selectData.directSystemId;
        graspingRulesStores.props.directSystemId = this.directSystemId;
        this.getParamsRule();
      };
      directSystemId.on.getLabel = (e) => {
        console.log(e);
      };

      const invoiceId = this.getRule('invoiceId');
      invoiceId.on.change = (e) => {
        if (!e) {
          this.bsInvoiceId = '';
          this.invoiceId = '';
          this.setValue({
            bsInvoiceId: '',
            invoiceName: '',
          });
          graspingRulesStores.props.invoiceId = '';
          this.getParamsRule();
          return;
        }
        const selectData = invoiceId.options.find((item) => item.id === e);
        this.bsInvoiceId = selectData.bsInvoiceId;
        this.invoiceId = selectData.id;
        this.setValue({
          bsInvoiceId: selectData.bsInvoiceId,
          invoiceName: selectData.invoiceName,
        });
        graspingRulesStores.props.invoiceId = this.invoiceId;
        this.getParamsRule();
      };
      const loginAcc = this.getRule('loginAcc');
      loginAcc.on.change = (e) => {
        const selectData = loginAcc.options.find((item) => item.loginAcc === e);
        this.setValue({
          accountId: selectData.id,
        });
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.fApi.disabled(true, ['directSystemId', 'invoiceId']);
        request.post('/mdm/mdmGrabRuleController/query', {
          id: this.formConfig.id,
          directSystemId: this.formConfig.directSystemId,
          invoiceId: this.formConfig.invoiceId,
          bsDirectId: this.formConfig.bsDirectId,
          bsInvoiceId: this.formConfig.bsInvoiceId,
        }).then((res) => {
          if (res.success) {
            this.setValue({ ...res.result, graspingRulesStores: res.result.stores.map((v) => v.storeId) });
            this.bsDirectId = res.result.bsDirectId;
            this.bsInvoiceId = res.result.bsInvoiceId;
            this.params = res.result.mdmRuleParamVos;
            this.directSystemId = res.result.directSystemId;
            this.invoiceId = res.result.invoiceId;
            graspingRulesStores.props.directSystemId = this.directSystemId;
            graspingRulesStores.props.invoiceId = this.invoiceId;
            if (this.formConfig.code === 'view') {
              graspingRulesStores.props.disabled = true;
            }
            this.$nextTick(() => {
              this.paramsRule = this.dealParamsRule(this.params);
            });
          }
        });
      }
    },
    // 获取规则参数
    getParamsRule() {
      if (this.paramsRule && this.paramsRule.length) {
        this.paramsRule.forEach((v) => {
          const item = v;
          this.rule.forEach((k, i) => {
            if (item.field === k.field) {
              this.rule.splice(i, 1);
            }
          });
        });
      }
      if (!this.bsDirectId || !this.bsInvoiceId) {
        return false;
      }

      request.post('/mdm/mdmGrabRuleController/paramList', {
        bsDirectId: this.bsDirectId,
        bsInvoiceId: this.bsInvoiceId,
      }).then((res) => {
        this.params = res.result;
        this.paramsRule = this.dealParamsRule(this.params);
      });
    },
    // 处理paramsRule
    dealParamsRule(params) {
      return params.map((v) => {
        const item = v;
        const ruleItem = {
          field: item.fieldEn,
          type: item.fieldClassify === 'SELECT' || item.fieldClassify === 'DATERANGE' ? 'select' : 'input',
          title: item.fieldCn,
          value: item.value,
          props: {
            clearable: true,
            disabled: this.formConfig.code === 'view',
            placeholder: item.fieldClassify === 'SELECT' || item.fieldClassify === 'DATERANGE' ? '请选择' : '请输入',
          },
          col: {
            md: { span: 8 },
          },
          validate: item.isRequired === '1' ? [
            {
              trigger: 'change',
              required: true,
              message: `${item.fieldCn}不能为空`,
            },
          ] : [],
          options: item.options && item.options.map((optionsItem) => ({
            label: optionsItem.optionName,
            value: optionsItem.optionValue,
          })),
        };
        this.fApi.append(ruleItem, 'title2');
        return ruleItem;
      });
    },
    // 开始时间change事件
    startDateChange(e) {
      const endDate = this.getRule('endDate');
      endDate.props = {
        ...endDate.props,
        pickerOptions: {
          selectableRange: `${e}:00 - 23:59:59`,
        },
      };
    },
    // 结束时间change事件
    endDateChange(e) {
      const startDate = this.getRule('startDate');
      startDate.props = {
        ...startDate.props,
        pickerOptions: {
          selectableRange: `00:00:00-${e}:00`,
        },
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      this.params = this.params.map((v) => {
        const item = v;
        item.value = formData[item.fieldEn];
        item.optionName = item.options && item.options.find((k) => k.optionValue === formData[item.fieldEn]).optionName;
        return item;
      });
      formData.stores = formData.graspingRulesStores ? formData.graspingRulesStores.map((item) => ({ storeId: item })) : [];
      formData.params = this.params;

      if (formData) {
        let url = '/mdm/mdmGrabRuleController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/mdm/mdmGrabRuleController/update';
          params.id = this.formConfig.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
