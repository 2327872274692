<template>
  <div>
      <div class="modal-container">
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>所有{{ transferConfig.title }}</span>
            </div>
            <div v-loading="loading">
              <!-- <el-input placeholder="请输入" v-model="filterText" clearable> </el-input> -->
              <el-tree
                v-if="showTree"
                class="filter-tree"
                node-key="id"
                show-checkbox
                :disabled="disabled"
                :data="treeData"
                :props="defaultProps"
                highlight-current
                :default-checked-keys="checkedKeys"
                :default-expand-all="defaultExpandAll"
                :expand-on-click-node="false"
                ref="treeRef"
              >
              </el-tree>
            </div>
          </el-card>
        </div>
        <div class="button-container">
          <div class="buttons">
            <!-- <el-button @click="addCurrentAndLower"
              >添加当前及以下{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button> -->
            <el-button @click="addCurrent" :disabled="disabled"
              >添加{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <el-button :disabled ="disabled" @click="removeTableData" icon="el-icon-arrow-left">移除</el-button>
          </div>
        </div>
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>已选{{ transferConfig.title }}</span>
            </div>
            <div>
              <vxe-table
                border
                :disabled ="disabled"
                show-overflow
                :data="selectData"
                row-id="id"
                @checkbox-all="selectAllEvent"
                @checkbox-change="selectChangeEvent"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="sort" width="60" title="序号">
                  <template v-slot="{ rowIndex }">
                    {{ rowIndex + 1 }}
                  </template>
                </vxe-table-column>
                <vxe-table-column field="name" title="门店名称"></vxe-table-column>
              </vxe-table>
            </div>
          </el-card>
        </div>
      </div>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import debounce from '../../../../../../utils/debounce';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    directSystemId: String,
    invoiceId: String,
  },
  data() {
    return {
      transferConfig: {
        title: '',
        api: '/mdm/mdmGrabRuleController/findGrabStoreList',
      },
      defaultProps: {
        children: 'childList',
        label: 'name',
        value: 'id',
      },
      treeData: [],
      defaultExpandAll: false,
      showTree: true,
      filterText: '',
      selectData: [],
      checkedKeys: [],
      selectTableData: [],
      loading: false,
    };
  },
  watch: {
    value(val) {
      // console.log(val);
      // this.selectData = val;
      // this.checkedKeys = val.map((item) => item.id);
      this.checkedKeys = val;
    },
    directSystemId: {
      handler(val) {
        this.getTreeList();
      },
    },
    invoiceId: {
      handler(val) {
        this.getTreeList();
      },

    },
    filterText(v) {
      if (v) {
        this.defaultExpandAll = true;
      } else {
        this.defaultExpandAll = false;
      }
      this.showTree = false;
      this.searchDebounce();
    },
  },
  created() {

  },
  mounted() {
    this.searchDebounce = debounce(() => this.getTreeList(), 500);
  },
  methods: {
    onClose() {
      this.modalConfig.visible = false;
    },
    onOk() {
      this.modalConfig.visible = false;
      this.$emit('onOk', this.selectData);
    },
    selectAllEvent({ checked, records }) {
      this.selectTableData = records;
    },
    selectChangeEvent({ checked, records }) {
      this.selectTableData = records;
    },
    // 获取数据
    getTreeList() {
      this.treeData = [];
      this.selectData = [];
      if (!this.directSystemId || !this.invoiceId) {
        return;
      }
      const data = {
        name: this.filterText,
        directSystemId: this.directSystemId,
        invoiceId: this.invoiceId,
      };
      this.loading = true;
      request.post(this.transferConfig.api, data).then((res) => {
        if (res.success) {
          this.getSelectData(res.result);
          console.log(this.selectData);
          this.treeData = this.setDisabled(res.result);
          this.showTree = true;
          this.loading = false;
        }
      });
    },
    // 获取详情已经选择数据
    getSelectData(data) {
      data.forEach((v) => {
        const item = v;
        if (item.childList.length > 0) {
          this.getSelectData(item.childList);
        }
        if (this.value && this.value.includes(item.id) && !this.selectData.map((i) => i.id).includes(item.id)) {
          this.selectData.push(item);
        }
      });
    },
    // 获取选中数据
    getCheckedNodes() {
      return this.$refs.treeRef.getCheckedNodes();
    },
    // 添加当前及以下组织
    addCurrentAndLower() {
      const disabledKeys = this.getCheckedNodes().filter((v) => !this.checkedKeys.includes(v.directSystemId));
      const tempArray = disabledKeys.map((v) => {
        const item = v;
        item.permissionSearchType = '2';
        return item;
      });
      this.selectData = this.selectData.concat(tempArray);
      this.checkedKeys = this.$refs.treeRef.getCheckedKeys();
      this.treeData = this.setDisabled(this.treeData);
    },
    // 添加当前组织
    addCurrent() {
      const disabledKeys = this.getCheckedNodes().filter((v) => !this.checkedKeys.includes(v.id));
      const tempArr = disabledKeys.filter((item) => !item.childList.length);
      this.selectData = this.selectData.concat(tempArr);
      this.checkedKeys = this.selectData.map((v) => v.id);
      console.log(this.checkedKeys);
      this.treeData = this.setDisabled(this.treeData);
      this.$emit('input', this.checkedKeys);
    },
    // 设置左侧树禁用
    setDisabled(array) {
      const selectId = this.selectData.map((item) => item.id);
      let tempArray = [];
      tempArray = array.map((v) => {
        const item = v;
        if (this.disabled) {
          item.disabled = true;
        } else if (selectId.includes(item.id) || item.forbidden) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        if (item.childList && item.childList.length) {
          this.setDisabled(item.childList);
        }
        return item;
      });
      return tempArray;
    },
    // 设置当前及以下为禁用
    setDisabledScope(array) {
      array.map((v) => {
        const item = v;
        item.disabled = true;
        this.checkedKeys.push(item.id);
        if (item.children && item.children.length) {
          this.setDisabledScope(item.children);
        }
        return item;
      });
    },
    // 移除
    removeTableData() {
      const selectTableKeys = this.selectTableData.map((item) => item.id);
      this.selectData = this.selectData.filter((item) => !selectTableKeys.includes(item.id));
      this.checkedKeys = this.selectData.map((item) => item.id);
      this.treeData = this.setDisabled(this.treeData);
      this.$emit('input', this.checkedKeys);
    },
  },
};
</script>

<style lang="less" scoped>
.modal-container {
  display: flex;
  height: 500px;
  .transfer-container {
    flex: 1 0 0;
    .card-container {
      height: 100%;
      overflow-y: scroll;
    }
  }
  .button-container {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .buttons {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
